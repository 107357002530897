import { MembershipType } from "membership_portal_api";

export class SelMembershipStorageAction 
{
  static readonly type = '[membership] set membership';
  constructor(public selMembership : MembershipType) {}

}

export class MembershipsStorageAction 
{
  static readonly type = '[memberships] set memberships';
  constructor(public memberships : MembershipType[]) {}

}
export class ActiveLangStorageAction 
{
  static readonly type = '[active] set lang';
  constructor(public lang : string) {}

}



