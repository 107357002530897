export class ManageColumnStorageAction
{
  static readonly type = '[ManageActionObj] set MngColObj';
  constructor(public MngColObj : any)
  {

  }

}

export class PerPageStorageAction
{
  static readonly type = '[PerPageActionObj] set PerPageObj';
  constructor(public PerPageObj  : any )
  {

  }
}

export class SortStorageAction
{
  static readonly type = '[SortActionObj] set SortObj';
  constructor(public sortObj  : any )
  {

  }
}
export class FilterStorageAction
{
  static readonly type = '[FilterActionObj] set FilterObj';
  constructor(public filterObj  : any )
  {

  }
}