import { createSelector } from "@ngxs/store";
import { AuthModel } from "src/app/model/auth.model";
import { MembershipModel } from "./membership.model";
import { MembershipState } from "./membership.state";

export const  selectSelMembership = () =>
 createSelector([ MembershipState ] , (state : MembershipModel) => state.selMembership ) ;

 export const  selectMemberships = () =>
 createSelector([ MembershipState ] , (state : MembershipModel) => state.memberships ) ;

 export const  selectLanguage = () =>
 createSelector([ MembershipState ] , (state : MembershipModel) => state.activeLanguage ) ;