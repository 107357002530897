import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {  PopoverController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { selectLoginResponse, selectToken } from 'src/app/pages/auth/auth.selectors';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { BASE_CONFIG } from 'src/app/util/base-settings';
import { APP_ROUTES, FILE_NAME } from 'src/app/util/constants';
import { UtilFunctions } from 'src/app/util/util';
import { PopoverComponent } from '../popover/popover.component';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ActiveLangStorageAction } from 'src/app/pages/membership-cards/membership.actions';
import { selectLanguage } from 'src/app/pages/membership-cards/membership.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit
{
  isPopoverOpen: boolean = false
  isShowLogin: boolean = false;
  @Input() isLoggedIn: boolean = false;
  @Input() user: any = null;
  
  activeLangCode: any;
  constructor(
    private logger: LoggerService,
    private router: Router,
    private util: UtilFunctions,
    private popoverController: PopoverController,
    private store:Store,

  ) { }

  @Output() openLogin = new EventEmitter<boolean>();

  ngOnInit()
  {
  
   
    this.onRouteChange(this.router.url);

    this.router.events.subscribe(event =>
    {
      if (event instanceof NavigationEnd)
      {
        this.onRouteChange(event.url);
      }
    });

    let userStore = this.store.select(selectLoginResponse()).subscribe(userData =>
      {
        if (userData)
        {
          this.user = this.util.cloneDeep(userData.logoDetails);
        }
        else
        {
          this.user = null
        }
      });
      let s = this.store.select(selectToken()).subscribe((details: string) =>
      {
        if (details)
        {
          this.isLoggedIn = true;
        }else{
          this.isLoggedIn = false;
        }
      })
  }

  onRouteChange(pCurrentUrl)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onRouteChange");

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onRouteChange",
        err.toString(),
        "onRouteChange",
      );
      this.logger.log(logRequest);
    }
  }

  async navToLogin()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToLogin");

      this.openLogin.emit(true);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in navToLogin",
        err.toString(),
        "navToLogin",
      );
      this.logger.log(logRequest);
    }
  }


  async onOpenPopover(e)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onOpenPopover");

      const viewOptions = this.getViewOptions();
      let popover;
     
      if(!this.isPopoverOpen) {
        this.isPopoverOpen = true
       popover = await this.popoverController.create({
        component: PopoverComponent,
        triggerAction:'hover',
        reference:'trigger',
        event:e,
        componentProps: {
          parent: this,
          propsData: {
            options: viewOptions,
          }
        }
      });

      await popover.present();
    }
    let isTop = await this.popoverController.getTop();
    if(popover){
    popover.onWillDismiss().then((d) => {
      this.isPopoverOpen = false
      if(isTop) {
      
      this.popoverController.dismiss()
      }
       });
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onOpenPopover",
        err.toString(),
        "onOpenPopover",
      );
      this.logger.log(logRequest);
    }
  }

  getViewOptions()
  {
    let retValue = [];
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getViewOptions");

      const navToProfile = this.navToProfile.bind(this);
      const logout = this.onLogout.bind(this);

      retValue = [
        // {
        //   id: 0,
        //   name: $localize`:@@header-comp.myProfileBtn:My Profile`,
        //   func: navToProfile,
        //   iconName: 'person-outline',
        // },
        {
          id: 1,
          name: $localize`:@@header-comp.logoutBtn:Logout`,
          func: logout,
          iconName: 'log-out-outline',
        }
      ];
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in getViewOptions",
        err.toString(),
        "getViewOptions",
      );
      this.logger.log(logRequest);
    }

    return retValue;
  }


  async navToProfile()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToProfile");

      const popover = await this.popoverController.getTop();
      if (popover)
      {
        await this.popoverController.dismiss();
      }
      this.util.navigate(APP_ROUTES.PROFILE);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in navToProfile",
        err.toString(),
        "navToProfile",
      );
      this.logger.log(logRequest);
    }
  }

  async onLogout()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onLogout");

      const popover = await this.popoverController.getTop();
      if (popover)
      {
        await this.popoverController.dismiss();
      }
      this.util.doLogout();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onLogout",
        err.toString(),
        "onLogout",
      );
      this.logger.log(logRequest);
    }
  }
}
