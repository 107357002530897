import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDialogComponent } from './custom-dialog.component';



@NgModule({
  declarations: [CustomDialogComponent],
  imports: [
    CommonModule,IonicModule
  ],
  exports:[CustomDialogComponent]
})
export class CustomDialogModule { }
