// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  logAppId:'stage.hui2ui',
  version:'1.4.55',
  apiUrl:'https://api.huihui.com.sg/',

  production: true,
  loggerUrl: '',
  paymentRedirectUrl: 'https://payment.huihui.com.sg',

  orgId: '1fd8d91e-5d6a-11ec-82d3-5bb97edc5027',
  adminFlow: false
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
