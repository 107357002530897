import { SignInResponse } from "userbase_package";

export class TokenStorageAction 
{
  static readonly type = '[token] set token';
  constructor(public token : string) {}

}

export class CaptchaStorageAction
{
  static readonly type = '[captchaText] set captchaText';
  constructor( public captchaText : string ) {}

}

export class LoginResponseAction
{
  static readonly type = '[LoginResponse] set loginResponse' ;
  constructor( public loginResponse : SignInResponse) {}

}