import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { RenewAction } from "./renew.actions";
import { RenewModel } from "./renew.model";



export const initialTokenState : RenewModel = {
  paramMembershipId: null,
  
}

export const STATE_TOKEN = new StateToken<RenewModel>('renew');

@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class RenewState
{
  constructor()
  {

  }
  @Action(RenewAction)
  setCreatedOrder(ctx : StateContext<RenewModel>, action : RenewAction)
  {
    ctx.patchState(
      {
        paramMembershipId:action.paramMembershipId?action.paramMembershipId:null
      }
    );
  }

 

}