import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { BASE_CONFIG } from 'src/app/util/base-settings';
import { FILE_NAME } from 'src/app/util/constants';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit
{

  // projDet: any;
  options: any;

  constructor(
    private logger: LoggerService,
    public navParams: NavParams
  )
  {
    // this.projDet = this.navParams.data.propsData.projDet;
    this.options = this.navParams.data.propsData.options;
  }

  ngOnInit() { }

  trackByIndex = (index: number): number => {
    return index;
  };
  

  getSelectedTab(pTabObj)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getSelectedTab");

      this.options.filter(tab =>
      {
        if (tab.id == pTabObj.id)
        {
          if (pTabObj.func)
          {
            pTabObj.func();
          }
        }
      });
    }
    catch (err)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.C_POPOVER_COMPONENT,
        "error in getSelectedTab",
        err.toString(),
        "getSelectedTab",
      );
      this.logger.log(body);
    }
  }
}
