


export class RenewAction 
{
  static readonly type = '[renew] create';
  constructor(public paramMembershipId : string) {}

}

