import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { APIResponse, Status } from "src/app/model/Response.model";
import { TokenStorageAction } from "src/app/pages/auth/auth.actions";
import { BASE_CONFIG } from "src/app/util/base-settings";
import { FILE_NAME, SERVICE_URL } from "src/app/util/constants";
import { SignInCredentials, SignInResponse } from "userbase_package";
import { HttpService } from "../../http/http.service";
import { LoggerService } from "../../logger/logger.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminLoginService {

  constructor(private logger:LoggerService,private httpService:HttpService,private store:Store) { }

  apiAdminLoginRes(req: SignInCredentials): Observable<APIResponse<SignInResponse>> {
    let retValue: Observable<APIResponse<SignInResponse>>;

    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("apiAdminLoginRes");
      if (req != null) {
        retValue = this.httpService.makePostRequest(SERVICE_URL.SIGNIN, req);
      } else {
        throw "invalid sign in request";
      }
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOGIN_SERVICE,
      "error in apiAdminLoginRes",
      err.toString(),
       "apiAdminLoginRes",
        );
       this.logger.log(logRequest);
    }
    return retValue;
  }
  renewToken(): Observable<boolean> {
    let retValue: Observable<boolean>;
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("renewToken");

     
            let renewalStatus$ = this.tokenRenewRes();
            return renewalStatus$.pipe(
              map((data) => {
               let authToken= data.status==Status.Success?data.data.authorization:''
                // if(this.authToken) {
                  this.store.dispatch(new TokenStorageAction(authToken));   
                //  }
                return data.status == Status.Success;
              })
            );
                 
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.TOKEN_RENEWAL_SERVICE,

        "error in renewToken",
        err.toString(),
        "renewToken"
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }
  tokenRenewRes() : Observable<APIResponse<any>>
  {
    let retValue : Observable<APIResponse<any>> ;
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log('tokenRenewRes');
      retValue = this.httpService.makePostRequest(SERVICE_URL.RENEW_JWT,null)
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOGIN_SERVICE,
        "error in tokenRenewRes",
        error.toString(),
        "tokenRenewRes"
      );
      this.logger.log(logRequest);
    }
    return retValue ;
  }
 

}
