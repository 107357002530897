import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActiveLangStorageAction } from 'src/app/pages/membership-cards/membership.actions';
import { selectLanguage } from 'src/app/pages/membership-cards/membership.selectors';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { BASE_CONFIG } from 'src/app/util/base-settings';
import { FILE_NAME } from 'src/app/util/constants';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
  lsLanguages:any=[
    {
      id:'en',
      code:'EN',
      name:'English',
      img:'../../../assets/svg/united.svg'
    },
    {
     id:'zh',
     code:'CHN',
     name:'Chinese',
     img:'../../../assets/svg/china.svg'
   }
  ]
  activeLangCode: any;

  constructor(  private store:Store,    private logger: LoggerService,) { }

  ngOnInit() {
    let  sLang = this.store.select(selectLanguage()).subscribe(lang =>{
      if(lang){
        this.activeLangCode=lang;
      }

    })
    sLang.unsubscribe();
  }
  onSelectLanguage(pEvent){
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onSelectLanguage");
    // console.error(pEvent)
    this.activeLangCode=pEvent.id;
    // console.error($localize.locale)
    // this.route.navigate([pEvent.id, APP_ROUTES.USER_MEMBERSHIP_DET]);
    // let data=require('../../../locale/messages.'+pEvent.id+'.json').translations;
    // console.error(data);
     this.store.dispatch(new ActiveLangStorageAction(pEvent.id));
          window.location.reload();
          // this.loader.showLoader();

    // this.http.get("./assets/mydata.json");
      // loadTranslations(data);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onSelectLanguage",
        err.toString(),
        "onSelectLanguage",
      );
      this.logger.log(logRequest);
    }
  }
}
