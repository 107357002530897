import { GetAllMemberDetailDBRes, MembershipType } from "membership_portal_api";
import { FormModel } from "./admin.model";

export class SelMemberStorageAction 
{
  static readonly type = '[member] set member';
  constructor(public selMember : GetAllMemberDetailDBRes) {}

}
export class FormDataStorageAction 
{
  static readonly type = '[form] set member';
  constructor(public formData : FormModel) {}

}


