import { Component, Input, OnInit } from "@angular/core";
import { AlertModalData, AlertModalStatus } from "../../model/alert-modal.model";
import { LoggerService } from "../../services/logger/logger.service";
import { BASE_CONFIG } from "../../util/base-settings";
import {  FILE_NAME } from "../../util/constants";

@Component({
  selector: 'ngx-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})
export class CustomDialogComponent implements  OnInit {

  readonly modalStatus =AlertModalStatus;
  @Input() public data: AlertModalData;
  @Input() isJwtModal:boolean=false;
  @Input() isInnerHtml: boolean = false;
  @Input() isPayment:boolean=false;
  @Input() isReset:boolean=false;

  emitFunc:any;
  isString: boolean;
  constructor(


    private logger: LoggerService,
  ) {}

   async onClose(isConfirm?:boolean): Promise<void> {
     try{
      if(BASE_CONFIG.IS_DEBUG) console.log("onClose");
  
       this.emitFunc(isConfirm,this.data.status);
  
     }
     
     catch (err) {
      let logRequest = this.logger.buildRequest(
       FILE_NAME.CUSTOM_DIALOG_COMPONENT,
     "error in onClose",
     err.toString(),
      "onClose",
       );
      this.logger.log(logRequest);
     }
   }
  ngOnInit(){
    this.isString=typeof this.data.desc=='string';
  }

 }
