import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { UserDetail } from "../../model/header.modal";
import { UserProfileAction } from "./header.action";


export const initialUserState: UserDetail = {
  userDetail: null
};

export const STATE_TOKEN = new StateToken<UserDetail>('user');

@State({
  name: STATE_TOKEN,
  defaults: initialUserState,
})

@Injectable()
export class UserProfileState
{
  constructor() { }

  @Action(UserProfileAction)
  setToken(ctx: StateContext<UserDetail>, action: UserProfileAction)
  {
    ctx.patchState(
      {
        userDetail: action.userDetail
      }
    );
  }

}