import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CardHoverDirective } from './card-hover.directive';
import { PageAnimationDirective } from './page-animation.directive';





@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [PageAnimationDirective,CardHoverDirective],
  declarations: [PageAnimationDirective,CardHoverDirective]
})
export class DirectiveModule { }
