import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { selectToken } from "src/app/pages/auth/auth.selectors";
import { environment } from "../../../environments/environment";
import { APIResponse } from "../../model/Response.model";
import { LoggerService } from "../../services/logger/logger.service";
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME, SERVICE_URL } from "../../util/constants";



@Injectable({
  providedIn: "root",
})
export class HttpService
{

  private BASE_URL: string = environment.apiUrl;
  dataSubject = new BehaviorSubject(null);


  constructor(
    private httpClient: HttpClient,
    private logger: LoggerService,
    private store: Store
  ) { }



  makePostRequest(
    serviceURL: string,
    requestBody: any | null,
    isResponseType?: boolean,
    pToken?: string
  ): Observable<APIResponse<any>>
  {
    let retValue;
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("makePostRequest");

      //this.loader.showLoader();
      let headers: HttpHeaders;
      if (
        serviceURL == SERVICE_URL.SIGNIN  ||
        serviceURL == SERVICE_URL.REGISTER ||
        serviceURL == SERVICE_URL.CREATE_ORDER|| serviceURL == SERVICE_URL.API_GET_MEMBERSHIPTYPE|| serviceURL == SERVICE_URL.GET_CITIZENSHIPLIST||serviceURL == SERVICE_URL.GET_ORDER_STATUS 
       ){
      headers = new HttpHeaders({
              Accept: "application/json",
              "Content-Type": "application/json",
              "Accept-Language": BASE_CONFIG.LANGUAGE,
              // orgid: BASE_CONFIG.ORG_ID
          });
        }
      //services without session
      else
      {
        let jwtToken = "";
        if (!pToken)
        {
          let s = this.store.select(selectToken()).subscribe((details: string) =>
          {
            if (details)
            {
              jwtToken = details;
              // jwtToken = 'sd';
            }
          });
        } else
        {
          jwtToken = pToken;
        }
        headers = new HttpHeaders({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
          "Accept-Language": BASE_CONFIG.LANGUAGE,
          orgid: BASE_CONFIG.ORG_ID
        }); //services with session
      }
      this.dataSubject.next(true);
      if (isResponseType)
      {
        let myHeader = {
          headers: headers,
          responseType: 'blob'
        };
        retValue = this.httpClient
          .post(
            this.BASE_URL + serviceURL,
            requestBody,
            // myHeader
            { headers: headers, responseType: 'blob' }
          )
          .pipe(finalize(() =>
          {
            this.dataSubject.next(false);
          }

          ));
      } else
      {


        retValue = this.httpClient
          .post<Observable<APIResponse<any>>>(
            this.BASE_URL + serviceURL,
            requestBody,
            { headers: headers }
          )
          .pipe(finalize(() =>
          {
            this.dataSubject.next(false);

            // this.loader.hideLoader();
            // this.loader.showLoader();
          }

          ));
      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HTTP_SERVICE,
        "error in makePostRequest",
        err.toString(),
        "makePostRequest"
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }

  //   isBlobError(err: any) {
  //     return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  //   }

  //   parseErrorBlob(err: HttpErrorResponse): Observable<any> {
  //     const reader: FileReader = new FileReader();

  //     const obs = Observable.create((observer: any) => {
  //       reader.onloadend = (e) => {
  //         observer.error(e);
  //         observer.complete();
  //       }
  //     });
  //     reader.readAsText(err.error);
  //     return obs;
  // }

}
