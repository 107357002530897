import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { RegistrationRes } from "membership_portal_api";
import { AuthModel } from "src/app/model/auth.model";
import { CreateOrderAction } from "./order.actions";


export const initialTokenState : RegistrationRes = {
  orderId: null,
  orderPaymentId: null,
  paymentProviderId:  null,
  token:null
}

export const STATE_TOKEN = new StateToken<RegistrationRes>('order');

@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class OrderState
{
  constructor()
  {

  }
  @Action(CreateOrderAction)
  setCreatedOrder(ctx : StateContext<RegistrationRes>, action : CreateOrderAction)
  {
    ctx.patchState(
      {
        orderId: action.order?action.order.orderId:null,
        orderPaymentId: action.order?action.order.orderPaymentId:null,
        paymentProviderId: action.order?action.order.paymentProviderId:null
      }
    );
  }

 

}