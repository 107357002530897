import { DirectiveModule } from './../../directives/directive.module';
import { PopoverModule } from './../../components/popover/popover.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CAvatarModule } from 'src/app/components/c-avatar/c-avatar.module';
import { HeaderModule } from 'src/app/components/header/header.module';
import { FooterModule } from './../../components/footer/footer.module';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';


@NgModule({
  imports: [
    CommonModule,
  FormsModule,
    IonicModule,
    HomePageRoutingModule,
    HeaderModule,
    FooterModule,
    CAvatarModule,
    PopoverModule,
    DirectiveModule
  ],
  declarations: [HomePage,]
})
export class HomePageModule {}
