import { ModalController } from '@ionic/angular';
import { NAV_PARAM, NAV_MODE, ROLE } from './../../util/constants';
import { Injectable } from "@angular/core";
import
{
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild,
  RoutesRecognized,
} from "@angular/router";
import { Observable } from "rxjs";
import { APP_ROUTES } from "../../util/constants";
import { LoggerService } from "../../services/logger/logger.service";
import { filter, map, pairwise, } from "rxjs/operators";
import { UtilFunctions } from '../../util/util';
import { Store } from "@ngxs/store";
import { selectLoginResponse, selectToken } from '../auth/auth.selectors';
import { CustomDialogComponent } from 'src/app/components/custom-dialog/custom-dialog.component';
import { AlertModalData, AlertModalStatus } from 'src/app/model/alert-modal.model';
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate
{
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private router: Router,
    private store: Store,
    private modal: ModalController,
    private util :UtilFunctions
  ) { }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree 
  {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) =>
      {
        this.currentUrl = e[1].url;
        this.previousUrl = e[0].urlAfterRedirects; // previous url
      });
  
    return this.store.select(selectLoginResponse()).pipe(
      map((details) =>
    {
      if (details&&details.authorization)
      {
        if (state.url == "/") {
         if(details.userRole==ROLE.SYSTEM_ADMIN){
          this.util.navigate(APP_ROUTES.ADMIN_MEMBER_ALL,null,null,true);

         }else{
          this.util.navigate(APP_ROUTES.USER_MEMBERSHIP_DET,null,null,true);

         }
        }
        return true
      }else{
        // queryParams: { mode: 'login',  }
        this.router.navigate([APP_ROUTES.LOGIN], {  replaceUrl: true, state: { allow: true } });
      }
    }))
    
   
  }
  



//  async sessExpModal(value){
//   let modalData: AlertModalData = {
//     buttonName: ["OK"],
//     desc: value,
//     status: AlertModalStatus.failed,
//     title: "Session Expired",
//   };
//     var dialogRef = await this.modal.create( {
//       component: CustomDialogComponent,
//       id:'Confirmationalert-modal',
//       componentProps:{
//         data:modalData,
//         isJwtModal:true,
//         emitFunc: await this.getConfirm.bind(this,value)
//       },  
//         backdropDismiss: false,

//     },);
//     await dialogRef.present();
 
//   }

  async getConfirm(pValue){
    if(pValue){
     await this.modal.dismiss();
     this.router.navigate([APP_ROUTES.PRODUCT], { queryParams: { mode: 'login',  error: pValue }, replaceUrl: true, state: { allow: true } });
    }else{
      this.modal.dismiss();
    }
 
  }
}

