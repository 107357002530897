export enum AlertModalStatus {
  success = "Success",
  failed = "Failed",
  confirm ='Confirmation'
}

export interface AlertModalData {
  status: AlertModalStatus;
  desc: string|Array<string>;
  buttonName: string|string[];
  title: string;
}

export interface CustomModalData {

  title: string;
}
