import { environment } from '../../../environments/environment';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { AuthState } from 'src/app/pages/auth/auth.state';
import { UserProfileState } from 'src/app/components/header/header.state';
import { MembershipState } from 'src/app/pages/membership-cards/membership.state';
import { OrderState } from 'src/app/pages/public/checkout/order.state';
import { DataTableState } from 'src/app/components/data-table/data-table.state';
import { AdminState } from 'src/app/pages/admin/admin.state';
import { RenewState } from 'src/app/pages/public/renew/renew.state';


const ngxsMainStates = 
[
  AuthState,
  UserProfileState,
  OrderState,
  MembershipState,
  DataTableState,
  AdminState,
  RenewState
];
const ngxsStorageStates = 
[
  AuthState,
  UserProfileState,
  OrderState,
  MembershipState,
  DataTableState,
  AdminState,
  RenewState

];

const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    suppressErrors: false,
    injectContainerState: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};

export const ngxsImports = [
  NgxsModule.forRoot(ngxsMainStates, ngxsConfig),
  NgxsStoragePluginModule.forRoot({
    key: ngxsStorageStates,
  }),
  NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  NgxsFormPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
];
