import { Component, OnInit } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { Store } from '@ngxs/store';
import { ClearStoreFunction } from './core/store/clearStore';
import { selectLanguage } from './pages/membership-cards/membership.selectors';
import { LoggerService } from './services/logger/logger.service';
import { BASE_CONFIG } from './util/base-settings';
import { FILE_NAME } from './util/constants';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private store: Store, private logger: LoggerService,private storeClear : ClearStoreFunction,) {
    console.log(BASE_CONFIG.VERSION);
  }
  ngOnInit(): void {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log('ngOnInit');
      this.storeClear.forceClearLocalStorage();

      let userStore = this.store.select(selectLanguage()).subscribe((lang) => {
        if (lang) {
          BASE_CONFIG.LANGUAGE = lang;
        }
        let data = require('./../locale/messages.' +
          BASE_CONFIG.LANGUAGE +
          '.json').translations;
          document.body.className=BASE_CONFIG.LANGUAGE;
        // load translations at runtime
        loadTranslations(data);
      });
      // userStore.unsubscribe();
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        'error in ngOnInit',
        err.toString(),
        'ngOnInit'
      );
      this.logger.log(logRequest);
    }
  }
}
