import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import * as cloneDeep from 'lodash.clonedeep';
import { CustomDialogComponent } from "../components/custom-dialog/custom-dialog.component";
import { ClearStoreFunction } from '../core/store/clearStore';
import { AlertModalData, AlertModalStatus } from "../model/alert-modal.model";
import { LOG_TYPE } from "../model/logger.model";
import { ErrorAlertVal, Status } from "../model/Response.model";
import { selectLoginResponse } from '../pages/auth/auth.selectors';
import { ApiAdminLoginService } from '../services/auth/login/api-admin-login.service';
import { ApiLogoutService } from '../services/auth/login/api-logout.service';
import { LoggerService } from "../services/logger/logger.service";
import { BASE_CONFIG } from './base-settings';
import { APP_ROUTES, FILE_NAME, ID, NAV_MODE, OrderStatus, PLATFORM, ROUTER_PARAM, ToastTypes } from './constants';


@Injectable({
  providedIn: "root",
})

export class UtilFunctions
{

  // config: NbToastrConfig;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  // position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = false;
  modalCount = 0;
  isJwtModalStarted: boolean;
  constructor(
    private dialog: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private apiLogoutService: ApiLogoutService,
    private toastController: ToastController,
    private store: Store,
    private storeClear: ClearStoreFunction,
    private navController: NavController,
    private _location: Location,
    private loginService : ApiAdminLoginService,
    private actionSheetController: ActionSheetController,

  ) { }
  getErrorAlertValue(pError, pIsNeedCode?, isAlertExist?): ErrorAlertVal
  {
    let errorAlert: ErrorAlertVal = {
      codeList: [],
      valueList: []
    };
    // Vue.toasted.show('hi');

    try
    {
      if (pError && pError.error && pError.error.length)
      {

        if (!(true))
        {//pIsNeedCode
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].value)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }

        else if (true)
        {//pIsNeedCode
          for (let index = 0; index < pError.error.length; index++)
          {
            if (pError.error[index].code)
            {
              errorAlert.codeList.push(pError.error[index].code);
            }
            if (pError.error[index].value)
            {
              errorAlert.valueList.push(pError.error[index].value);
            }
          }
        }
        let logRequest = this.logger.buildRequest(
          FILE_NAME.UTIL,
          "error in getErrorAlertValue",
          JSON.stringify(pError),
          "getErrorAlertValue",
        );
        this.logger.log(logRequest);
      }

      else
      {
        errorAlert.valueList = ["Failed"];
      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getErrorAlertValue",
        err.toString(),
        "getErrorAlertValue",
      );
      this.logger.log(logRequest);
    }

    //  return errorAlert;
    return errorAlert;
  }

   handleJWT(pError, pDonotNav?):boolean
  {
    let retValue: boolean = false;

    try
    {

      if (pError)
      {
        let queryParams = {} as any;
        let isJwt = false;
        if (pError.error)
        {
          for (let index = 0; index < pError.error.length; index++)
          {
            const err = pError.error[index];
            if (err.code == Status.ERROR_JWT_01 ||
              err.code == Status.ERROR_JWT_02 ||
              err.code == Status.ERROR_AUT2 ||
              err.code == Status.ERROR_PER)
            {
              queryParams[ROUTER_PARAM.PARAM] = [err.code, err.value];
              isJwt = true;

            }
          }
        }

        if (isJwt)
        {

          retValue = true;
          if(!pDonotNav){
          if (queryParams.param.length > 0)
          {
            let sLogin = this.store.select(selectLoginResponse()).subscribe(async (loginResponse) =>
            {
              if (!loginResponse || loginResponse && loginResponse.authorization)
              {
                let url = this.router.url;
                if (url == '/' + APP_ROUTES.USER_MEMBERSHIP_DET||url == '/' + APP_ROUTES.ADMIN_MEMBER_ALL)
                {
                  this.navigate(APP_ROUTES.LOGIN,null,null, true);

                } else
                {
                  await this.navToLogin(queryParams);

                }

              }
            });

            sLogin.unsubscribe();
          } else
          {

            retValue = false;
          }
          }
        }
      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getUserRoles",
        err.toString(),
        "getUserRoles",
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }


  async navToLogin(queryParams?)
  // :Promise<boolean>
  {
    let retValue: boolean = false;

    try
    {
      this.modalCount = 0;
      let modal= await this.dialog.getTop();
     let id=modal?modal.id:null;
     if(id!=ID.JWT_MODAL&&!this.isJwtModalStarted)
      {
        this.isJwtModalStarted=true;

        let modalData: AlertModalData = {
          buttonName: [$localize`:@@alertModal.okBtn:OK`],
          // queryParams && queryParams.param[0] == Status.ERROR_PER ? queryParams.param[1] : "Your session has expired. Please enter your email / password again to continue.",
          desc: queryParams && queryParams.param.length>1 ? queryParams.param[1] : $localize`:@@common-sessionExpireText:Your session has expired. Please login again to continue.`,
          status: AlertModalStatus.failed,
          title: $localize`:@@sessionExpire-title:Session Expired`,
        };

        var dialogRef = await this.dialog.create({
          component: CustomDialogComponent,
          id: ID.JWT_MODAL,
          componentProps: {
            data: modalData,
            isJwtModal: true,
            emitFunc: await this.getConfirm.bind(this, queryParams.param)
          },
          backdropDismiss: false,
          cssClass:'sess-modal'
        });
        await dialogRef.present();
        this.isJwtModalStarted=false;

      }

    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navToLogin",
        err.toString(),
        "navToLogin",
      );
      this.logger.log(logRequest);
    }
    // return retValue;
  }

  async renew(){
    try{
      if (BASE_CONFIG.IS_DEBUG) console.log("renew");
   let isSuccess= await this.loginService.renewToken().toPromise().then();
  if(!isSuccess){
    let data={"status":"Error","error":[{"code":Status.ERROR_JWT_01,"value":$localize`:@@common-sessionExpireText:Your session has expired. Please login again to continue.`}]}
  await this.handleJWT(data)
  }

    }catch (err : any) {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in renew",
        err.toString(),
        "renew",
        LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }

  async getConfirm(pQueryParams)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log('getConfirm');
//       let modal = await this.dialog.getTop();
// if(modal){
//   this.dialog.dismiss()
// }
//       this.navigate(APP_ROUTES.LOGIN,  {  code: pQueryParams[0], error: pQueryParams[1] } ,null,true);
      let modal= await this.dialog.getTop();

      let id=modal?modal.id:null;
      // &&!modal.isOpen
     if(id&&this.modalCount<3){

      await this.dialog.dismiss().then(async ()=>{
        this.modalCount++;
        await this.getConfirm(pQueryParams);


      });
     }
      this.router.navigate([APP_ROUTES.LOGIN],{queryParams : {code:pQueryParams[0],error :pQueryParams[1]},replaceUrl:true,state: { allow: true }});
    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getConfirm",
        err.toString(),
        "getConfirm",
      );
      this.logger.log(logRequest);
    }
  }
  async onGetActionSheet(pButton){
    try{
      if (BASE_CONFIG.IS_DEBUG) console.log("onGetActionSheet");
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'my-custom-class',
        buttons: pButton
      });
      await actionSheet.present();
    }catch (err : any) {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in onGetActionSheet",
        err.toString(),
        "onGetActionSheet",
        LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }
  updateCurrRouteQuery(pQuery): void
  {
    try
    {

      if (this.router.url)
      {
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: pQuery,
            queryParamsHandling: 'merge'
          });

      } else
      {
        throw 'invalid current route';
      }


    } catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in updateCurrRouteQuery",
        err.toString(),
        "updateCurrRouteQuery",
      );
      this.logger.log(logRequest);
    }
  }



  handleErrorStatus(fileName: string, functionName: string, errorMessage: string)
  {
    try
    {
      let modalData: AlertModalData = {
        buttonName: $localize`:@@alertModal.okBtn:OK`,
        desc: "Something went wrong !",
        status: AlertModalStatus.failed,
        title: null,
      };

      const dialogRef = this.dialog.create({
        component: CustomDialogComponent,
        componentProps: {
          data: modalData,

        },

      });

      let logRequest = this.logger.buildRequest(
        fileName,
        "error in " + functionName,
        errorMessage,
        functionName
      );
      this.logger.log(logRequest);

    } catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in handleErrorStatus",
        error.toString(),
        "handleErrorStatus"
      );
      this.logger.log(logRequest);
    }
  }

  async navigate(pUrl , pQueryParams? , state?,replaceUrl? ){
    try{
      if (BASE_CONFIG.IS_DEBUG) console.log("navigate");
      // if(pQueryParams)
      //  await this.navController.navigateRoot([pUrl],{queryParams : pQueryParams})
      // else if(state)
      //   await this.navController.navigateRoot([pUrl],{ state : state})
      // else if(replaceUrl)
      //   await this.navController.navigateRoot([pUrl],{replaceUrl:true});
      // else
       await this.navController.navigateRoot([pUrl],{queryParams : pQueryParams,state:state,replaceUrl:replaceUrl});

    }catch (err : any) {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in navigate",
        err.toString(),
        "navigate",
        LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }
  goBack(pIsDefaultNav?)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("goBack");

      this._location.back();


    } catch (err: any)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in goBack",
        err.toString(),
        "goBack",
        LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }



  /**
   * @param  {string} pMsg Toast Message
   * @param {string} pToastType Toast Type (Success, Failure, Warning)
   * @param {boolean} isNeedBtn Need Button (Default False)
   * @param {string} pButtonTxt Toast Button Text (Optional)
   * @param {number} pDuration Toast Duration (Optional)
   */
  async toShowToast(pToastType: string, pMsg: string, pButtonTxt?: string, pDuration?: number, pPath?: string, isNeedBtn: boolean = false, pIsNavFirst?)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toShowToast");

      if (pIsNavFirst)
      {
        // await this.navigate([pPath],{ state: { allow: true }})
        await this.navigate('/' + pPath);
      }

      let button: any[] = [];
      if (isNeedBtn)
      {
        button = [
          {
            //  text: pButtonTxt ? pButtonTxt : $localize`:@@util.toastBtnOK:OK`,
            text: $localize`:@@alertModal.okBtn:OK`,
            handler: () =>
            {
              //  console.error('Toasted');
            }
          }
        ];
      }
      let toast = await this.toastController.create({
        message: pMsg,
        duration: pDuration ? pDuration : 3000,
        // icon: 'information-circle',
        cssClass: 'toast-custom-class',
        position: 'bottom',
        color: 'dark',
        buttons: button
      });

      if (pPath)
      {

        toast.onDidDismiss().then(() =>
        {
          if (!pIsNavFirst)
          {
            this.router.navigate([pPath], { state: { allow: true } });
          }
        });
      }

      await toast.present();
    }
    catch (err)
    {
      const body = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in toShowToast",
        err.toString(),
        "toShowToast",
        LOG_TYPE.ERROR
      );
      this.logger.log(body);
    }
  }


  async downLoadFile(data: any, name: string, pIsNewTab?: boolean)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("downLoadFile");
      // let data =
      let blob = new Blob([data], { type: data.type });

      if (data.type.includes("application/json"))
      {
        let res = JSON.parse(await blob.text());
        if (res && res.status == Status.Error)
        {
          let isJwt = this.handleJWT(res);
          if (!isJwt)
          {
            let errorMsg = this.getErrorAlertValue(res, true, false);
            if (errorMsg && errorMsg.valueList && errorMsg.valueList.length > 0)
            {
              this.toShowToast(errorMsg.valueList.toString(), ToastTypes.Failure);
            }
          }
        }
      } else
      {
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
        if (pIsNewTab)
        {
          let win = window.open(downloadURL);
        }
        window.URL.revokeObjectURL(downloadURL);
        // this.loader.hideLoader()
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in downLoadFile",
        err.toString(),
        "downLoadFile",
      );
      this.logger.log(logRequest);
    }
  }

  toPrintFile(data: any, name: string)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("toPrintFile");

      let blob = new Blob([data], { type: data.type });

      // var blob = this.b64toBlob(data, 'application/zip', data.byteLength);
      var downloadURL = window.URL.createObjectURL(blob);
      window.open(downloadURL);

    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in toPrintFile",
        err.toString(),
        "toPrintFile",
      );
      this.logger.log(logRequest);
    }
  }

  cloneDeep(pValue): any
  {
    var cloneDeepObj = cloneDeep;
    let retValue: any;

    try
    {
      retValue = cloneDeepObj(pValue);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in cloneDeep",
        err.toString(),
        "cloneDeep",
      );
      this.logger.log(logRequest);
    }
    return retValue;
  }

  // createLogRequest(pComponent, pFunction, pError)
  // {

  //   let returnValue;
  //   try
  //   {
  //     returnValue = this.logger.buildRequest(
  //       pComponent,
  //       "error in " + pFunction,
  //       // pError,
  //       pError && pError.message ?
  //         (typeof pError.message === 'string' ? pError.message : JSON.parse(JSON.stringify(pError.message))) :
  //         typeof pError === 'string' ? pError : JSON.parse(JSON.stringify(pError)),
  //       pFunction, pError.status
  //     );
  //   } catch (err)
  //   {
  //     let logRequest = this.logger.buildRequest(
  //       FILE_NAME.UTIL,
  //       "error in createLogRequest",
  //       err.toString(),
  //       "createLogRequest",
  //     );
  //     this.logger.log(logRequest);
  //   }
  //   return returnValue;
  // }

  doLogout(toStayInSamePage?): void
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("doLogout");

      this.apiLogoutService.apiLogoutRes().subscribe({
        next: val =>
        {
          this.storeClear.toClearStore();

          this.navigate(APP_ROUTES.LOGIN,null,null,true);

        },
        error: (error: HttpErrorResponse) =>
        {
          this.storeClear.toClearStore();
            this.navigate(APP_ROUTES.LOGIN,null,null,true);

          let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in doLogout",
            error.message.toString(),
            "doLogout"
          );
          this.logger.log(logRequest);
        }
      });

    }
    catch (error)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in doLogout",
        error.toString(),
        "doLogout"
      );
      this.logger.log(logRequest);
    }
  }


  getFileReader() {
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("getFileReader");

    var reader = new FileReader();
     return reader;

    } catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getFileReader",
            err.toString(),
            "getFileReader",
          );
          this.logger.log(logRequest);
    }
  }

  appendPagerFooter() {
    try {
      // if (BASE_CONFIG.IS_DEBUG) console.log("appendPagerFooter");
  let huihuiFooter= document.getElementById('huihui-footer');

      let huihuiFooterPag= document.getElementById('huihui-footer-pag');
      let huihuiFooterPagLength= document.getElementById('huihui-footer-pag-length')
      let huihuiFooterRowOption= document.getElementById('huihui-footer-row-option')

      let paginationBottom= document.getElementsByClassName('pagination-bottom')[0]
      let huihuiPaginationLenoption= document.getElementsByClassName('huihui-pagination-lenoption')[0];
      let huihuiRowOption= document.getElementsByClassName('huihui-row-option')[0];


      if(huihuiFooter){
        if(huihuiFooterPag&&paginationBottom){
        if(huihuiFooterPag.getElementsByClassName('pagination-bottom')[0]){

              huihuiFooterPag.removeChild(huihuiFooterPag.childNodes.item(huihuiFooterPag.childNodes.length-1))



        }

          huihuiFooterPag.appendChild(paginationBottom);
        }

        if(huihuiPaginationLenoption&&huihuiFooterPagLength){
        if(huihuiFooterPagLength.getElementsByClassName('huihui-pagination-lenoption')[0]){

          huihuiFooterPagLength.removeChild(huihuiFooterPagLength.childNodes.item(huihuiFooterPagLength.childNodes.length-1))

        }


          huihuiFooterPagLength.appendChild(huihuiPaginationLenoption);
        }

        if(huihuiRowOption&&huihuiFooterRowOption){
        if(huihuiFooterRowOption.getElementsByClassName('huihui-row-option')[0]){

          huihuiFooterRowOption.removeChild(huihuiFooterRowOption.childNodes.item(huihuiFooterRowOption.childNodes.length-1))

        }

          huihuiFooterRowOption.appendChild(huihuiRowOption);
        }

      }

    } catch (err : any) {
        let logRequest = this.logger.buildRequest(
            FILE_NAME.UTIL,
            "error in appendPagerFooter",
            err.toString(),
            "appendPagerFooter",
          );
          this.logger.log(logRequest);
    }
  }

  getStatusText(pOrderStatus,pIsGetAsClass)
  {
    let statusText="";
    let statusClass="";
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("getStatusText");
      switch(pOrderStatus){

        case OrderStatus.Fulfillment_Failed:
          statusText= $localize`:@@userMembership-failedStatus:Failed`;
          statusClass='status_item_fail';
          break;

        case OrderStatus.Fulfillment_Success:
          statusText= $localize`:@@userMembership-paidStatus:Paid`;
          statusClass='status_item_paid';
          break;

        case OrderStatus.Payment_Failed:
          statusText= $localize`:@@userMembership-failedStatus:Failed`;
          statusClass='status_item_fail';
          break;

        case OrderStatus.Waiting_For_Payment:
          statusText= $localize`:@@userMembership-pendingStatus:Pending`;
          statusClass='status_item_pending';
          break;

        default:
          break;
     }
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
        "error in getStatusText",
        error.toString(),
        "getStatusText"
      );
      this.logger.log(logRequest);
    }
    return pIsGetAsClass?statusClass:statusText;
  }


  copyTextToClipboard(pText,pMgs){
    try {
      if (BASE_CONFIG.IS_DEBUG) console.log("copyTextToClipboard");

    var tempInput = document.createElement("input");
    tempInput.value = pText;
    document.body.appendChild(tempInput);
    tempInput.select();
    //  tempInput.setSelectionRange(0, 99999); /* For mobile devices */

    //Copy the text inside the text field
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    this.toShowToast(ToastTypes.Success,pMgs);
    } catch (err) {
  let logRequest = this.logger.buildRequest(
    FILE_NAME.UTIL,
    "error in copyTextToClipboard",
    err.toString(),
    "copyTextToClipboard",
  );
  this.logger.log(logRequest);
   }


 }

}
