import { createSelector } from "@ngxs/store";
import { AuthModel } from "./auth.model";
import { AuthState } from "./auth.state";

export const  selectToken = () =>
 createSelector([ AuthState ] , (state : AuthModel) => state.token ) ;

export const selectCaptchaText = () =>
createSelector([ AuthState ], (state : AuthModel) => state.captchaText);

export const selectLoginResponse = () =>
createSelector([ AuthState], (state : AuthModel) => state.loginResponse);