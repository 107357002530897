import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from 'src/app/util/constants';
import { AuthGuard } from '../gaurd/auth.guard';
import { HomePage } from './home.page';


const routes: Routes = [
  // {
  //   path: '',
  //   component: ProductPage
  // },
  
    {
    path: '',
    component: HomePage,
    // canActivate:[AuthGuard],

   children: [
      {
      path: '',
      redirectTo: APP_ROUTES.USER_MEMBERSHIP_DET,
      pathMatch: "full",
    },
    {
      path: APP_ROUTES.USER_MEMBERSHIP_DET,
      loadChildren: () => import('./user-membership-det/user-membership-det.module').then( m => m.UserMembershipDetPageModule)
    },
  
    // {
    //   path: APP_ROUTES.PRODUCT,
    //   loadChildren: () => import('../home/product/product.module').then(m => m.ProductPageModule),
    //   canActivate: [AuthGuard]
    // },
    // {
    //   path: APP_ROUTES.PRODUCT_DETAIL,
    //   loadChildren: () => import('../home/product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
    //   canActivate: [AuthGuard]
    // },
    // {
    //   path: APP_ROUTES.MY_ORDER,
    //   loadChildren: () => import('../home/orders/orders.module').then(m => m.OrdersPageModule),
    //   canActivate: [AuthGuard],
    // },
    // {
    //   path: APP_ROUTES.PROFILE,
    //   loadChildren: () => import('../home/profile/profile.module').then(m => m.ProfilePageModule),
    //   canActivate: [AuthGuard],
    // },
    // {
    //   path: '**',
    //   redirectTo: APP_ROUTES.CHECKOUT,
    //   pathMatch: "full",
    // }
  ]
},
{
  path: 'membership-cards',
  loadChildren: () => import('../membership-cards/membership-cards.module').then( m => m.MembershipCardsPageModule)
},

{
  path: APP_ROUTES.CHECKOUT,
  loadChildren: () => import('../public/checkout/checkout.module').then( m => m.CheckoutPageModule)
},
{
  path: APP_ROUTES.RENEW,
  loadChildren: () => import('../public/renew/renew.module').then( m => m.RenewPageModule)
}
 

  


 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
