import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageSelectionComponent } from './language-selection.component';



@NgModule({
  declarations: [LanguageSelectionComponent],
 imports:[CommonModule,IonicModule,ReactiveFormsModule],
  exports: [LanguageSelectionComponent]
})
export class LanguageSelectionModule { }
