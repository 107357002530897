import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonFooter, IonHeader, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { ClearStoreFunction } from 'src/app/core/store/clearStore';
import { AlertModalStatus } from 'src/app/model/alert-modal.model';
import { ErrorAlertVal } from 'src/app/model/Response.model';
import { ApiLogoutService } from 'src/app/services/auth/login/api-logout.service';
import { HttpService } from 'src/app/services/http/http.service';
import { BASE_CONFIG } from 'src/app/util/base-settings';
import { LoggerService } from './../../services/logger/logger.service';
import { APP_ROUTES, FILE_NAME } from './../../util/constants';
import { UtilFunctions } from './../../util/util';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit
{
  isPopoverOpen: boolean = false
  isLoggedIn: boolean = false
  email: string = null;
  user: any;
  menuLs = [];
  showLoading:boolean=true;
  alertMsg: ErrorAlertVal|string ="";
  modalStatus: AlertModalStatus;
  isProgress: boolean=false;
  @ViewChild('head') header: IonHeader;
  @ViewChild('foot') footer: IonFooter;
  constructor(
    private util: UtilFunctions,
    private logger: LoggerService,
    private modalController: ModalController,
    private httpService: HttpService,
    private menuController: MenuController,
    private popoverController: PopoverController,
    private logOut : ApiLogoutService,
    private clearStore : ClearStoreFunction,
    private router : Router
  )
  {
 
  }

  async ngOnInit()
  {
    // let s = this.store.select(selectToken()).subscribe((details: string) =>
    // {
    //   if (details)
    //   {
    //     this.isLoggedIn = true;
    //   }else{
    //     this.isLoggedIn = false;
    //   }
    // })
    
    // this.currentRoute.queryParams.subscribe(
    //   (params: Params) =>
    //   {
    //     if (params && Object.keys(params).length != 0 && params.constructor === Object)
    //     {
    //       if (params[NAV_PARAM.Mode] && params[NAV_PARAM.Mode] == NAV_MODE.Login)
    //       {
    //         this.onOpenLoginModal();
    //       }

    //     } else
    //     {
    //       ///create flow
    //     }


    //   },
    //   (error: HttpErrorResponse) =>
    //   {
    //     throw error;
    //   }
    // );

    // let userStore = this.store.select(selectLoginResponse()).subscribe(userData =>
    // {
    //   if (userData)
    //   {
    //     this.user = this.util.cloneDeep(userData.logoDetails);
    //   }
    //   else
    //   {
    //     this.user = null
    //   }
    // });
  }
  ngAfterViewInit()
  {
    this.httpService.dataSubject.subscribe(res =>
    {
      this.isProgress = null;
      this.isProgress = res;

    });
  }

  closeMenu()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("closeMenu");
      if(this.menuController) {
        this.menuController.close();
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in closeMenu",
        err.toString(),
        "closeMenu",
      );
      this.logger.log(logRequest);
    }
  }

  navToProduct()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToProduct");

      this.util.navigate(APP_ROUTES.PRODUCT);
      this.menuController.close();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in navToProduct",
        err.toString(),
        "navToProduct",
      );
      this.logger.log(logRequest);
    }
  }

  async onCloseSidebar(){
    await  this.menuController.close();
  }

  doLogout(pIsCloseSidemenu?) : void
  {
    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("doLogout");

      this.logOut.apiLogoutRes().subscribe({
        next : async val => {
          if(pIsCloseSidemenu){
          await this.onCloseSidebar();
          }
          this.clearStore.toClearStore();
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
        },
        error : (error : HttpErrorResponse) => {
          this.clearStore.toClearStore();
          this.util.navigate(APP_ROUTES.LOGIN,null,null,true);
          let logRequest = this.logger.buildRequest(
            FILE_NAME.HOME_PAGE,
            "error in doLogout",
            error.message.toString(),
            "doLogout"
          )
          this.logger.log(logRequest);
        }
      })
      
    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in doLogout",
        error.toString(),
        "doLogout"
      );
      this.logger.log(logRequest);
    }
  }


  onClickMenu(ev,pMenuObj,isSubmenu?){
    ev.preventDefault();
    ev.stopPropagation();
    if(!isSubmenu){
    for(let i=0;i<this.menuLs.length;i++)
    {
      this.menuLs[i].isOpen=false;
    }
    pMenuObj.isOpen=!pMenuObj.isOpen;
  }
    this.util.navigate(pMenuObj.link);
  }


  isActive(pMenuObj) {

    let className=pMenuObj.isOpen?'expand':'collapse';
    let status= pMenuObj.link==this.router.url.split('?')[0]?className+' active':className+' inactive';
    return  (pMenuObj&&pMenuObj.children&&pMenuObj.children.length)?status +' has-children':status;
  //  if(this.menuObj.link==pMenuObj.link){
  //  }
  }

  // navToLogin()
  // {
  //   try
  //   {
  //     if (BASE_CONFIG.IS_DEBUG) console.log("navToLogin");
  //     if(!this.isLoggedIn) {
  //     this.util.navigate(APP_ROUTES.PRODUCT,{mode: NAV_MODE.Login});
  //     }
  //     else{
  //       this.util.navigate(APP_ROUTES.PROFILE);
  //     }
  //     this.menuController.close();
  //   }
  //   catch (err)
  //   {
  //     let logRequest = this.logger.buildRequest(
  //       FILE_NAME.HOME_PAGE,
  //       "error in navToLogin",
  //       err.toString(),
  //       "navToLogin",
  //     );
  //     this.logger.log(logRequest);
  //   }
  // }




  navToOrder(pIsValid: boolean)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToOrder");

      if (pIsValid)
      {
        this.modalController.dismiss();
        this.util.navigate(APP_ROUTES.MY_ORDER);
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in navToOrder",
        err.toString(),
        "navToOrder",
      );
      this.logger.log(logRequest);
    }
  }

 
 


  async onOpenPopover(e)
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onOpenPopover");

      const viewOptions = this.getViewOptions();
      let popover
     
      
      if(!this.isPopoverOpen) {
        this.isPopoverOpen = true
       popover = await this.popoverController.create({
        component: PopoverComponent,
        triggerAction:'hover',
        reference:'trigger',
        event: e,
        componentProps: {
          parent: this,
          propsData: {
            options: viewOptions,
          }
        }
      });

      await popover.present();
    }
    
    let isTop = await this.popoverController.getTop();
    if(popover){
    popover.onWillDismiss().then((d) => {
      this.isPopoverOpen = false
      if(isTop) {
        
      this.popoverController.dismiss()
      }
       });
      }
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in onOpenPopover",
        err.toString(),
        "onOpenPopover",
      );
      this.logger.log(logRequest);
    }
  }

  getViewOptions()
  {
    let retValue = [];
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("getViewOptions");

      const navToProfile = this.navToProfile.bind(this);
      const logout = this.onLogout.bind(this);

      retValue = [
        // {
        //   id: 0,
        //   name: $localize`:@@header-comp.myProfileBtn:My Profile`,
        //   func: navToProfile,
        //   iconName: 'person-outline',
        // },
        {
          id: 1,
          name: $localize`:@@header-comp.logoutBtn:Logout`,
          func: logout,
          iconName: 'log-out-outline',
        }
      ];
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in getViewOptions",
        err.toString(),
        "getViewOptions",
      );
      this.logger.log(logRequest);
    }

    return retValue;
  }

  async navToProfile()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("navToProfile");
this.closeMenu()
      const popover = await this.popoverController.getTop();
      if (popover)
      {
        await this.popoverController.dismiss();
      }
      this.util.navigate(APP_ROUTES.PROFILE);
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in navToProfile",
        err.toString(),
        "navToProfile",
      );
      this.logger.log(logRequest);
    }
  }

  async onLogout()
  {
    try
    {
      if (BASE_CONFIG.IS_DEBUG) console.log("onLogout");
      this.closeMenu()
      const popover = await this.popoverController.getTop();
      if (popover)
      {
        await this.popoverController.dismiss();
      }
      this.util.doLogout();
    }
    catch (err)
    {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HEADER_COMPONENT,
        "error in onLogout",
        err.toString(),
        "onLogout",
      );
      this.logger.log(logRequest);
    }
  }


  toDeleteEmptyChildren(pSubMenu){
    try {
      pSubMenu.children.filter( val => {
        delete val.displayorder;
        if(val.children.length==0){
        delete val.children;
        }
          if(val.children && val.children.length>0){
            this.toDeleteEmptyChildren(val.children)
          }
      })
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.HOME_PAGE,
        "error in toDeleteEmptyChildren",
        err.toString(),
        "toDeleteEmptyChildren");
      this.logger.log(logRequest);
    }
   return pSubMenu
  }


}
