export class FILE_NAME
{
  //service files
  static readonly HTTP_SERVICE = "http.service.ts";
  static readonly HOME_PAGE = "home.page.ts";
  static readonly SIGN_IN_SERVICE = "sign-in.service.ts";
  static readonly TOKEN_RENEWAL_SERVICE = "token-renewal.service.ts";
  static readonly LOADER_SERVICE = "loader.service.ts";
  static readonly PUBLIC_SERVICE = "public.service.ts";
  static readonly ORDER_SERVICE = "order.service.ts";
  static readonly TEST_SERVICE = "test.service.ts";
  static readonly MENU_SERVICE = "menu.service.ts";

  static readonly MEMBER_SERVICE = "member.service.ts";
  static readonly CAPTCHA_SERVICE = "captcha.service.ts";
  static readonly LOGIN_SERVICE = "login.service.ts";
  static readonly LOGOUT_SERVICE = "logout.service.ts";
  static readonly PAYMENT_SERVICE = "payment.service.ts";
  static readonly FORGOT_PASSWORD_SERVICE = "api-forgot-pwd.service.ts";
  static readonly RESET_PASSWORD_SERVICE = "reset-pwd.service.ts";


  //component files
  static readonly APP_COMPONENT = "app.component.ts";
  static readonly ALERT_MODAL_COMPONENT = "alert-modal.component.ts";
  static readonly ACTION_POPOVER_COMPONENT = "action-popover.component.ts";
  static readonly RESET_PASSWORD_COMPONENT ="reset-password.component.ts"
  
  static readonly FORM_ERRORS_COMPONENT = "form-errors.component.ts";
  static readonly CUSTOM_DIALOG_COMPONENT = "custom-dialog.component.ts";
  static readonly CUSTOM_MODAL_COMPONENT = "custom-modal.component.ts";
  static readonly CUSTOM_TEXT_COMPONENT = "custom-text.component.ts";
  static readonly HEADER_COMPONENT = "header.component.ts";
  static readonly FOOTER_COMPONENT = "footer.component.ts";
  static readonly C_POPOVER_COMPONENT = "popover.component.ts";
  static readonly CAPTCHA_COMPONENT = "captcha.component.ts";
  static readonly DATA_TABLE_COMPONENT = "data-table.component.ts"
  static readonly SEARCH_COMPONENT= "search.component.ts";
  static readonly PAGINATION_LENGTH_COMPONENT= "pagination-length.component.ts";
  static readonly ADMIN_HEADER_COMPONENT = "admin-header.component.ts";
  static readonly TABLE_VALUE_ACTION = "table-value-action.component.ts";
  static readonly ION_DATEPICKER = "ion-datepicker.component.ts";
  static readonly FILE_UPLOAD = "file-upload.component.ts";

  static readonly MEMBER_FORM = "member-form.component.ts";

  //utils
  static readonly UTIL = "util.ts";
  static readonly MY_ACCOUNT = 'my-account.component.ts';
  static readonly STORE_CLEAR = 'clearStore.ts';
  static readonly TABLE_SORTING='table-sorting.ts'

  //guards
  static readonly AUTH_GUARD = "auth.guard.ts";
  static readonly CONTRACTOR_GUARD = "contractor.guard.ts";

  //pages
  static readonly PRODUCT_PAGE = "product.page.ts";
  static readonly PRODUCT_DETAILS_PAGE = "product-detail.page.ts";
  static readonly ORDER_PAGE = "order.page.ts";
  static readonly PROFILE_PAGE = "profile.page.ts";
  static readonly LOGIN_PAGE = "login.page.ts";
  static readonly MEMBERSHIP_DETAILS_PAGE = "user-membership-det.page.ts";
  static readonly MEMBER_ALL_PAGE = "member-all.page.ts";
  static readonly PAYMENT_REPORTS_PAGE = "payment-reports.page.ts";
  static readonly MEMBERSHIP_CARDS_PAGE = "membership-cards.page.ts";
  static readonly CHECKOUT_PAGE = "checkout.page.ts";
  static readonly RENEW_PAGE = "renew.page.ts";

}

export class SERVICE_URL
{

  //AUTH API
  static readonly SIGNIN = "api/auth/signin";
  static readonly VERIFY_OTP = 'api/public/user/verifyOtp';
  static readonly REGISTER = 'api/public/user/create';
  static readonly RENEW_JWT = "api/auth/renew";
  static readonly CREATE_ORDER = 'api/public/user/order/create';
  static readonly MEMBERSHIP_CREATE_ORDER = 'api/membership/createOrder';
  static readonly START_TEST = 'api/public/test/start';
  static readonly LOGOUT = 'api/auth/logout';
  static readonly GET_CAPTCHA = "api/utils/getCaptcha";
  static readonly FORGOT_PASSWORD = "api/userManagement/forgotPassword";
  static readonly GET_ROLES = "api/userManagement/getRoles";
  static readonly GET_MENUS = "api/utils/getMenus";
  static readonly RESET_PASSWORD = 'api/userManagement/resetPassword';

  //PUBLIC API
  static readonly API_GET_MEMBERSHIPTYPE = 'api/membershipType/get';
  static readonly API_MEMBERSHIP_REGISTER = 'api/membership/registration';
  static readonly GET_MEMBER_DETAIL = 'api/member/detail/get';
  static readonly RESEND_CARD_MEMBERSHIP = 'api/membership/card/resend';
  static readonly SEND_PAYMENT_REMINDER = 'api/payment/sendReminder';
  static readonly UPDATE_MEMBER = 'api/membership/update';
  static readonly VALIDATE_NRIC = 'api/membership/NRIC/validate';

  
  static readonly GET_MEMBERSHIP_DETAIL = 'api/membership/detail/get';

 
  static readonly API_GET_USER_PROFILE = 'api/userManagement/getAccountDetails';
  static readonly API_UPDATE_USER_PROFILE = 'api/user/updateSelfUser';
  static readonly API_GET_USER_ORDERS = 'api/public/user/order/getAll';
  static readonly API_START_PUBLIC_TEST = 'api/public/test/start';
  static readonly API_DOWNLOAD_PUBLIC_TEST_REPORT = 'api/report/public/test/report/download';
  static readonly GET_MEMBERSHIP_CARD_FRONT_VIEW = 'api/membership/card/img/font';
  static readonly GET_MEMBERSHIP_CARD_BACK_VIEW = 'api/membership/card/img/back';
  static readonly DOWNLOAD_MEMBERSHIP_CARD = 'api/membership/card/pdf';

  static readonly GET_CITIZENSHIPLIST = "api/citizenshipList/get";
  
  static readonly GET_COUNTRYLIST = "api/countryList/get";
  static readonly GET_PAYMENTHISTORY = "api/member/paymentHistory";
  static readonly GET_ORDER_STATUS = "api/order/status";
  static readonly GET_PAYMENT_REPORT = "api/paymentReport/get";
  static readonly GET_ALL_MEMBERDETAIL= "api/memberDetail/getAll";

  
}
export class ROUTER_PARAM
{
  static readonly PARAM = "param";

}
export enum StatusFilter {
  All = "All"
}
export class PAGE_NAMES {
  static readonly DASHBOARD_LIST = "Dashboard List";
  static readonly EMPLOYEE_LIST ="Employee List" ;
  static readonly WAREHOUSE_LIST = "Warehouse List";
  static readonly SUPPLIER_LIST ="Supplier List";
  static readonly COMPANY_LIST = "Company List";
  static readonly CLIENT_LIST = "Client List" ;
  static readonly USER_LIST = "User List";
  static readonly QUOTATION_LIST = "Quotation List";
  static readonly INVOICE_LIST = "Invoice List";
  static readonly PURCHASE_ORDER_LIST = "Purchase Order List";
  static readonly PROJECT_LIST = "Project list" ;
  static readonly PRODUCT_LIST = "Product List";
  static readonly PRODUCT_CATEGORY_LIST = "Product Category List";
  static readonly INVENTORY_LIST = "Inventory List";
  static readonly CLIENT_STATEMENT_LIST ="Client Statement List";
  static readonly INVOICE_REPORT_LIST = "Invoice Report List";
  static readonly PAYMENT_METHOD_LIST = "Payment Method List"
  static readonly PAYMENT_TERMS_LIST = "Payment Terms List";
  static readonly APPOINTMENT_LIST = "Appointment List";
  static readonly PROJECT_ADD_PURCHASE_ORDER_LIST = "Project Add Purchase Order List";
  static readonly PROJECT_ADD_QUOTATION_LIST = "Project Add Quotation List";
  static readonly PRODUCT_REQUIREMENT_LIST = "Product Requirement List";
  static readonly PROJECT_INVOICE_LIST = "Project Invoice List";
  static readonly PROJECT_PAYMENT_LIST = "Project Payment List";
  static readonly VIEW_REQUIREMENT_LIST = "View Requirement List";
  static readonly PROJECT_QUOTATION_LIST = "Project Quotation List";
  static readonly PROJECT_PURCHASE_ORDER_LIST = "Project Purchase Order List";
  static readonly PROJECT_ORDER_LIST = "Project Order List";
  static readonly PROJECT_EDIT_INVOICE = "Project Edit Invoice";
  static readonly CON_APPOINTMENTS = "ConAppointments";

}
export enum CurrTab{
  active = 'Active',
  deleted = 'Deleted',
}
export class ACTIONS {
  static DELETE = 'Delete';
  static EDIT = 'Edit';
  static RESTORE = 'Restore';
  static DOWNLOAD = 'Download';
  static MANAGE = 'Manage';
  static REMINDER = 'Send Reminder';
  static ADD = "Add";
  static VIEW_PAYMENT ="View Payment";
  static PAYMENT = "Payment" ;
  static DETAILS = "Details" ;
  static PAGE_BREAK = "Page Break";
  static GENERATE_INVOICE = "Generate Invoice";
  static PRINT = 'Print';
  static ARCHIVE = "Archive";
  static VOID = "Void";
  static UPDATE = "Update"
  static UPDATE_QTY = "Update Qty"
  static COMPANY ="Company";
  static VIEW ="View";
  static SEND_QUOTATION ="Send Quotation";
  static DROPDOWN ="Dropdown";
  static SEND_INVOICE ="Send Invoice";
  static ADD_PRODUCT ="Add Product";
  static SUBMIT ="Submit";
  static SHARE = 'Share';
  static MARK_READ ='Mark Read';
}
export class APP_ROUTES
{
  static readonly HUIHUI_HOME = "https://huihui.com.sg/new";

  static readonly HOME = "/";
  static readonly PRODUCT = "product";
  static readonly PRODUCT_DETAIL = "product/detail";
  static readonly MY_ORDER = "order";
  static readonly PROFILE = "profile";
  static readonly PAYMENTGATEWAY = "/processpayment/entry";

  static readonly CHECKOUT = "registration";
  static readonly USER_MEMBERSHIP_DET = "user-profile";
  static readonly LOGIN = "auth/login";
  static readonly ADMIN_MEMBER_ALL = "admin/member/all";
  static readonly MEMBERSHIP_CARDS = "membership-cards";
  static readonly ADMIN_MEMBER_EDIT = "admin/member/edit";
  static readonly ADMIN_PAYMENT_REPORTS = "admin/payment-reports";
  static readonly RENEW = "renew";

}

// export class API_VALUES {
//   static ORG_ID = environment.orgId;
// }


export enum PAYMENT_QUERY
{
  PROVIDER_ID = "provider_id",
  ORDER_PAYMENT_ID = "order_payment_id",
  SESSION_ID = "session_id",
  REDIRECT_URL = "redirect_url",
  AUTH_TOKEN = "auth_token",
  ORG_ID = "org_id",
  TOKEN = "token",
  STATUS = "status",
  ERROR_CODE = "error_code",
  ERROR_MESSAGE = "error_msg",
}
export class DATE_TIME_FORMAT
{
  static API_DATE_FORMAT = "yyyy-MM-dd";
  static API_TIME_FORMAT = "hh:mm:ss";
  static DATE_PAYHIS_DISPLAY_FORMAT = "dd MMM, yyyy";
  static TIME_PAYHIS_DISPLAY_FORMAT = "h:mm aa";
  static FILE_DATE_FORMAT = "yyyy-MM-dd";
  static DATE_TABLE_DISPLAY_FORMAT = "dd MMM yyyy";
  static HUI_HUI_DATE_TABLE_DISPLAY_FORMAT = "dd MMM yyyy";

  
  static REPORT_TABLE_DISPLAY_FORMAT = "dd MMM yyyy hh:mm aa";


  
}

export class ROLES_PERMISSIONS
{
  static DELETE = 'D';
  static APPROVE = 'A';
  static READ = 'R';
  static WRITE = 'W';
}
export class PLATFORM
{
  static android = "ANDROID";
  static ios = "IOS";
  static web = 'WEB';
}

export enum ToastTypes
{
  Success = "success",
  Warning = "warning",
  Failure = "danger"
}
export class STYLE_CONFIG
{
  static readonly M_BREAKPOINTS = [0, 0.2, 0.5, 1];
  static readonly M_INITIALBREAKPOINT = 0.2;
}

export enum ROLE
{
  SYSTEM_ADMIN = 'System.Admin',
  USER = 'App.User'
}
export enum ID
{
  JWT_MODAL = 'jwt-modal',
  ORDER_PAYMENT_ERROR='order-payment-err',
  ORDER_PAYMENT_SUCCESS='order-payment-success'

}

export class LOCAL_STORAGE_KEYS
{
  static readonly FORCE_CLEAR_VERSION = "forceClearVersion";
}

export enum ModalType
{
  Login = 1,
  Register = 2,
  OTP = 3,
}
export enum CurrencySymbol
{
  Dollar = '$',
}

export enum ERROR_CODES
{
  MAIL_EXIST = 'UMC23',
  CARD_NOT_AVAILABLE = 'MC01',
  PAYMENT_TOKEN_EXPIRED='UHL01',
}

export enum NAV_MODE
{
  Login = 'login',
}

export enum NAV_PARAM
{
  Mode = 'mode',
  Membership = 'memberid',
  Lang = 'lang'

}

export enum ALERT_MODAL_STATUS
{
  SUCCESS = "success",
  WARNING = "warning",
  FAIL = "danger"
}

export enum OrderStatus
{
  // new = 1,
  // waitingForPayment = 8,
  // paymentFailed = 9,
  // paymentSuccess = 10,
  // paymentPendingFromPaymentGateWay = 11
  New = 1,
  Waiting_For_Payment = 8,
  Payment_Failed = 9,
  Fulfillment_Success = 10,
  Fulfillment_Failed = 11
}

export enum ResendOtpCount
{
  count = 20
}

export enum TestCodeStatus
{
  NOT_USED = 1,
  ASSIGNED = 2,
  STARTED = 3,
  COMPLETED = 4,
  PENDING = null
}
export enum ProductName
{
  Title = 'Huihui'
}
export enum APIRefreshTime
{
  refresh_time = 30 // n seconds
}

export enum SegmentTitle
{
  who_we_are = 1,
  vision = 2,
  history = 3,
}

export interface ActionPopover {
  id: PopoverActionCode,
  name: string,
  disable: boolean
}

export enum PopoverActionCode {
  EDIT = 1,
  COMPANY = 2,
  DOWNLOAD = 3 ,
  SEND_INVOICE = 4 ,
  PRINT = 5,
  SEND_REMAINDER = 6 ,
  ARCHIVE = 7,
  UPDATE = 8 ,
  UPDATE_QUANTITY = 9,
  DELETE = 10,
  VIEW = 11,
  VIEW_PAYMENT = 12,
  MANAGE = 12,
  RESTORE = 13,
  SEND_QUOTATION = 14,
  PAYMENT = 15,
  DETAILS = 17,
  PAGE_BREAK = 18,
  GENERATE_INVOICE = 19,
  SHARE = 20 ,
  MARK_READ = 21 ,
  RESEND_CARD_MEMBERSHIP = 23 ,
  SEND_PAYMENT_REMINDER = 24
}

export enum TableEmptyValue{
  emptyValue = '-'
}


export enum MembershipTypes {
  Ordinary = "ordinary_membership",
  Associate = "associate_membership",
  Life = "life_membership"
}
export enum CITIZENSHIP_LIST  {
  CITIZENSHIP = "citizenship",
  NON_SINGAPOREAN = "non-singaporean/PR",
  SIGNAPOREAN_PR = "signaporean_PR",
  SIGNAPOREAN="signaporean"
}

export interface FilterAction
{
  id : OrderStatus | StatusFilter ,
  name : string ,
  disable? : boolean,
  checked? : boolean
}

export enum LANGUAGE  {
  EN='en',
  ZH='zh'
}