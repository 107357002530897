import { RegistrationRes } from "membership_portal_api";


export class CreateOrderAction 
{
  static readonly type = '[order] create';
  constructor(public order : RegistrationRes) {}

}

