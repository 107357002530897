import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { AuthModel } from "src/app/model/auth.model";
import { ActiveLangStorageAction, MembershipsStorageAction, SelMembershipStorageAction } from "./membership.actions";
import { MembershipModel } from "./membership.model";


export const initialTokenState : MembershipModel = {
  selMembership : null ,
  memberships:null,
  activeLanguage:'en'
 
}

export const STATE_TOKEN = new StateToken<MembershipModel>('membership');

@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class MembershipState
{
  constructor()
  {

  }
  @Action(SelMembershipStorageAction)
  setSelMembership(ctx : StateContext<MembershipModel>, action : SelMembershipStorageAction)
  {
    ctx.patchState(
      {
        selMembership : action.selMembership?action.selMembership:null
      }
    );
  }

  @Action(MembershipsStorageAction)
  setMembershipLs(ctx : StateContext<MembershipModel>, action : MembershipsStorageAction)
  {
    ctx.patchState(
      {
        memberships : action.memberships?action.memberships:null
      }
    );
  }

  @Action(ActiveLangStorageAction)
  setSelectedLang(ctx : StateContext<MembershipModel>, action : ActiveLangStorageAction)
  {
    ctx.patchState(
      {
        activeLanguage : action.lang?action.lang:null
      }
    );
  }
}