import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { FormDataStorageAction, SelMemberStorageAction } from "./admin.actions";
import { MemberModel } from "./admin.model";


export const initialAdminState : MemberModel = {
  selMember : null ,
  formData :null
 
}

export const STATE_TOKEN = new StateToken<MemberModel>('member');

@State({
  name: STATE_TOKEN,
  defaults: initialAdminState,
})

@Injectable()
export class AdminState
{
  constructor()
  {

  }
  @Action(SelMemberStorageAction)
  setToken(ctx : StateContext<MemberModel>, action : SelMemberStorageAction)
  {
    ctx.patchState(
      {
        selMember : action.selMember?action.selMember:null
      }
    );
  }
  @Action(FormDataStorageAction)
  setFormData(ctx : StateContext<MemberModel>, action : FormDataStorageAction)
  {
    ctx.patchState(
      {
        formData : action.formData?action.formData:null
      }
    );
  }
  

}