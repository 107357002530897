import { Directive, ElementRef } from '@angular/core';
import { createAnimation  } from '@ionic/core';
import { AnimationController } from '@ionic/angular';

@Directive({
  selector: '[appPageAnimation]'
})
export class PageAnimationDirective {

  constructor(private animationCtrl: AnimationController, private el: ElementRef) {
    this.dropIn();
  }

  private dropIn() {
    const animation = createAnimation()
              .addElement(this.el.nativeElement)
              .duration(500)
              .iterations(1)
              .fromTo('transform', 'translateY(-20%)', 'translateY(0%)');
    animation.play();
  }
}

@Directive({
  selector: '[appMenuAnimation]'
})
export class MenuAnimationDirective {

  constructor(private animationCtrl: AnimationController, private el: ElementRef) {
    this.dropIn();
  }

  private dropIn() {
    const animation = createAnimation()
              .addElement(this.el.nativeElement)
              .duration(1000)
              .iterations(1)
              .fromTo('transform', 'translateX(-20%)', 'translateX(0%)');
    animation.play();
  }
}