
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { APIResponse } from "src/app/model/Response.model";
import { BASE_CONFIG } from "src/app/util/base-settings";
import { FILE_NAME, SERVICE_URL } from "src/app/util/constants";
import { HttpService } from "../../http/http.service";
import { LoggerService } from "../../logger/logger.service";

@Injectable({
  providedIn: 'root'
})
export class ApiLogoutService {

  constructor(private logger:LoggerService,private httpService:HttpService) { }

  apiLogoutRes(): Observable<APIResponse<any>> {
    let retValue: Observable<APIResponse<any>>;

    try {
      if(BASE_CONFIG.IS_DEBUG)  console.log("apiLogoutRes");
        retValue = this.httpService.makePostRequest(SERVICE_URL.LOGOUT, null);
      
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.LOGOUT_SERVICE,
      "error in apiLogoutRes",
      err.toString(),
       "apiLogoutRes",
        );
       this.logger.log(logRequest);
    }
    return retValue;
  }
}
