import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ManageColumnStorageAction } from "src/app/components/data-table/data-table.actions";
import { UserProfileAction } from "src/app/components/header/header.action";
import { FormDataStorageAction } from "src/app/pages/admin/admin.actions";
import {  LoginResponseAction, TokenStorageAction } from "src/app/pages/auth/auth.actions";
import { MembershipsStorageAction, SelMembershipStorageAction } from "src/app/pages/membership-cards/membership.actions";
import { CreateOrderAction } from "src/app/pages/public/checkout/order.actions";
import { RenewAction } from "src/app/pages/public/renew/renew.actions";

import { LoggerService } from "../../services/logger/logger.service";
import { BASE_CONFIG } from "../../util/base-settings";
import { FILE_NAME, LOCAL_STORAGE_KEYS } from "../../util/constants";

@Injectable({
  providedIn: "root"
})

export class ClearStoreFunction
{
  constructor(private store: Store, private logger: LoggerService, )
  {

  }

  toClearStore()
  {
    try
    {
      this.store.dispatch(new TokenStorageAction(null));
      this.store.dispatch(new LoginResponseAction(null));
      this.store.dispatch(new UserProfileAction(null));
      this.store.dispatch(new CreateOrderAction(null));
      // this.store.dispatch(new ManageColumnStorageAction(null));
      this.store.dispatch(new SelMembershipStorageAction(null));
      this.store.dispatch(new RenewAction(null));
      this.store.dispatch(new MembershipsStorageAction(null));
      this.store.dispatch(new FormDataStorageAction(null));


    } catch (error) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.STORE_CLEAR,
        "error in clearStoreFunction",
        error.toString(),
        "toClearStore"
      );
      this.logger.log(logRequest);
    }
  }


  forceClearLocalStorage() {
    try {
        if (BASE_CONFIG.IS_DEBUG) console.log("forceClearLocalStorage");
       let forceClearVersion= localStorage.getItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION);
       
        if(BASE_CONFIG.isForceClearLocalStorage&&forceClearVersion!=BASE_CONFIG.VERSION){
        this.toClearStore();        //  localStorage.clear();
        this.clearOnForceAction();

         localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION,BASE_CONFIG.VERSION);
        }
    } catch (err) {
      let logRequest = this.logger.buildRequest(
        FILE_NAME.UTIL,
      "error in forceClearLocalStorage",
      err.toString(),
       "forceClearLocalStorage",
        );
       this.logger.log(logRequest);
    }
}
 clearOnForceAction(){
   localStorage.clear();
  // this.store.dispatch(new ManageColumnStorageAction(null));

 }
}