import { environment } from '../../environments/environment';


export class BASE_CONFIG {
    static readonly IS_DEBUG=!environment.production;
    static readonly VERSION=environment.version;
    static readonly LOGGER_URL = environment.loggerUrl;
    static readonly LOG_APP_ID=environment.logAppId
    static readonly ORG_ID = environment.orgId
    static readonly MIN_NO_OF_TABLE_ROWS = 10;
    static readonly DATE_FORMAT = 'dd-mmm-yyyy';
    static readonly CURRENCY_CODE = 'USD'
    static readonly CURRENCY_SYMBOL= '$'

    static PLATFORM='';
    static BROWSER='';
    static isForceClearLocalStorage=false;
    static LANGUAGE='en';

    }
