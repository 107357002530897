import { LoggerService } from './../../services/logger/logger.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-avatar',
  templateUrl: './c-avatar.component.html',
  styleUrls: ['./c-avatar.component.scss'],
})
export class CAvatarComponent implements OnInit
{

  avatarDetails: any = {
    displayName: null,
    photoUrl: null,
    email: null
  };

  @Input() dpUrl: any;
  @Input() firstName: any;
  @Input() lastName: any;
  @Input() size: any;
  @Input() showDetails: boolean = false;
  @Input() emailId: string;

  constructor() { }

  ngOnInit() { }

}
