import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { CaptchaStorageAction, LoginResponseAction, TokenStorageAction } from "./auth.actions";
import { AuthModel } from "./auth.model";

export const initialTokenState : AuthModel = {
  token : null ,
  captchaText : null ,
  loginResponse : null
}

export const STATE_TOKEN = new StateToken<AuthModel>('token');
export const STATE_CAPTCHA_TEXT = new StateToken<AuthModel>('captchaText');
export const STATE_LOGIN_RESPONSE = new StateToken<AuthModel>('loginResponse');

@State({
  name: STATE_TOKEN,
  defaults: initialTokenState,
})

@Injectable()
export class AuthState
{
  constructor()
  {

  }
  @Action(TokenStorageAction)
  setToken(ctx : StateContext<AuthModel>, action : TokenStorageAction)
  {
    ctx.patchState(
      {
        token : action.token?action.token:null
      }
    );
  }

  @Action(LoginResponseAction)
  setLoginResponse(ctx : StateContext<AuthModel> , action : LoginResponseAction)
  {
    ctx.patchState(
      {
        loginResponse : action.loginResponse
      }
    );
  }

  @Action(CaptchaStorageAction)
  setCaptchaText(ctx : StateContext<AuthModel> , action : CaptchaStorageAction)
  {
    ctx.patchState(
      {
        captchaText : action.captchaText?action.captchaText:null
      }
    );
  }

}