import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  
  // {
  //   path: '',
  //   // component: HomePage,
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },



  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthModule)
  },
  { path: 'reset-password', loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(i => i.ResetPasswordModule) },

 
  {                                                                                                          
    path: environment.adminFlow?'admin':'',
    loadChildren: ()=> {
      if(environment.adminFlow){
      return  import('./pages/admin/admin.module').then( m => m.AdminPageModule);
      }else{
      return  import('./pages/home/home.module').then( m => m.HomePageModule);

      }
    }
  },
  // {                                                                                                          
  //   path: 'admin',
  //   loadChildren: ()=> {
    
  //     return  import('./pages/admin/admin.module').then( m => m.AdminPageModule);
     
  //   }
  // },
  // {                                                                                                          
  //   path: '',
  //   loadChildren: ()=> {
    
  //     return  import('./pages/home/home.module').then( m => m.HomePageModule);
  //   }
  // },
{
    path: '**',
    redirectTo:environment.adminFlow?'admin':'',
  },

  // {
  //   path: '',
  //   redirectTo: 'product',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
