import { Directive, ElementRef, HostListener } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ActiveLangStorageAction } from '../pages/membership-cards/membership.actions';
import { BASE_CONFIG } from '../util/base-settings';

@Directive({
  selector: '[cardHoverDirctive]'
})
export class CardHoverDirective {
  queryActiveLang: any;

  constructor( private route:ActivatedRoute,    private store: Store,

    ) {
    let queryParms=this.route.queryParams.subscribe((param) => {
        if(param['lang']){
            this.queryActiveLang=param['lang'];
        }
       })
       queryParms.unsubscribe();
  }
  @HostListener('mouseover') onMouseOver() { 
    this.store.dispatch(new ActiveLangStorageAction(this.queryActiveLang?this.queryActiveLang:BASE_CONFIG.LANGUAGE));

  }
}

